import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const RomantycznyPage = () => (
  <Layout>
    <SEO title="Romantyczny Weekend w Gdańsku" />
    <section className="single-trip-description">
      <div className="wrap">
        <div className="negative-margin-left negative-margin-photo">
          <img src={`/static/romantyczny-weekend.jpg`} alt="" />
        </div>
        <div>
          <h3>NASTROJOWY WEEKEND NAD MORZEM</h3>
          <h1>Romantyczny Weekend w Gdańsku!</h1>
          <p>
            Z przyjemnością zapraszamy Was na romantyczny weekend w Gdańsku. Ty
            i Twoja druga połówka możecie spędzić wyjątkowe chwile w pięknym
            nadmorskim mieście Gdańsk. Pragniemy, aby był to dla Was udany czas,
            dlatego wybraliśmy atrakcje sprzyjające romantycznemu nastrojowi.
            Zapraszamy na wycieczkę statkiem, z którego pokładu można podziwiać
            falujące polskie morze oraz panoramę Trójmiasta. Lokalna kuchnia w
            otoczeniu historycznych murów Gdańska pobudzi Wasze zmysły, a jak
            wiadomo – przez żołądek do serca. Urokliwe uliczki, lokalni artyści
            oraz szum morza tworzą kameralną atmosferę, która mamy nadzieję,
            naładuje Was pozytywnymi emocjami.
          </p>
        </div>
        <div className="trip-plan">
          <h4>W programie proponujemy:</h4>
          <div className="day">
            <div className="day-number">Dzień 1</div>
            <div className="day-plan">
              <ul>
                <li>Transfer z lotniska / dworca PKP</li>
                <li>Zakwaterowanie w hotelu / pensjonacie / apartamencie</li>
                <li>Spacer z przewodnikiem urokliwymi uliczkami Gdańska</li>
                <li>
                  Kolacja w restauracji na szczycie najwyższego budynku w
                  Gdańsku
                </li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 2</div>
            <div className="day-plan">
              <ul>
                <li>Relaks dla dwojga w SPA </li>
                <li>Rejs statkiem do Sopotu, spacer na molo i wzdłuż plaży</li>
                <li>
                  Romantyczna kolacja w restauracji z widokiem na morze przy
                  zachodzie słońca
                </li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 3</div>
            <div className="day-plan">
              <ul>
                <li>
                  Wycieczka statkiem stylizowanym na XVII w. – na pokładzie jest
                  bar i możliwość wysłuchania koncertu szantowego na żywo
                </li>
                <li>Transfer na lotnisko / dworzec PKP</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
        <div className=" prices">
          <div>
            CENA OD: 450 zł / za osobę
            <p> CENA ZAWIERA</p>
            <ul>
              <li>Transfer </li>
              <li>Zakwaterowanie </li>
              <li>Towarzystwo przewodnika turystycznego </li>
            </ul>
          </div>
          <div>
            Cena zależy od ilości osób, programu i standardu noclegu. Cenę
            kalkulujemy dla każdego klienta indywidualnie, ponieważ zależy ona
            od ilości osób, programu i rodzaju noclegu. Każdy program
            dostosowany jest do życzeń naszych gości oraz do ich harmonogramu
            podróży.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default RomantycznyPage
